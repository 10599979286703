(function( $ ) {

    $.fn.collapsabaleText = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var openLink = jqContainer.find('.action .action-btn .open>a').first();
            var closeLink = jqContainer.find('.action .action-btn .close>a').first();
            openLink.click(function(e) {
                e.preventDefault();
                jqContainer.addClass('open');
            });
            closeLink.click(function(e) {
                e.preventDefault();
                jqContainer.removeClass('open');
            });
        });

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.collapsabale-text').collapsabaleText();
});
