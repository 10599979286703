import catalogStyles from '../sass/catalog.scss';
import collapsableTextJS from './includes/collapsabale_text.js';

$(".catalog-item-slider").each(function() {
    var jqSliderConainer = $(this);
    jqSliderConainer.find('.catalog-item-slider-holder').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: false,
        prevArrow: jqSliderConainer.find('.prev').first(),
        nextArrow: jqSliderConainer.find('.next').first(),
        appendDots: jqSliderConainer.find('.nav').first(),
        autoplay: false,
        adaptiveHeight: false,
        variableWidth: false,
        fade: false,
        speed: 500,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                    arrows: false,
                    dots: false,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    dots: false,
                }
            },
        ]
    });
});